const initialState = {
  limits: {},
  createdTemplate: {},
  createdTemplateItems: {},
  searchResults: [],
  itemsWithTasks: [],
  allUnits: [],
  checkMatch: {},
  lastCreatedTemplate: {},
  listTaskStatus: {},
  templateTypesList: [],
};

export const getAllUnitsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getAllUnitsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  allUnits: payload,
});

export const getAllUnitsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const copyUnitsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const copyUnitsSuccess = (state, { payload }) => ({
  ...state,
  createdTemplate: {
    ...state.createdTemplate,
    [payload.unit.Id]: payload.unit,
  },
  createdTemplateItems: {
    ...state.createdTemplateItems,
    ...payload.items,
  },
  isLoading: false,
});

export const copyUnitsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getLimitsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
  searchResults: [],
});

export const getLimitsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  limits: payload,
});

export const getLimitsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getItemsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getItemsSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  itemsWithTasks: payload,
});

export const getItemsFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const createListTemplateRequest = (state, { payload }) => ({
  ...state,
  error: '',
  createdTemplateItems: payload.anotherUnitTemplate ? state.createdTemplateItems : {},
  isLoading: true,
});

export const createListTemplateSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  createdTemplate: {
    ...state.createdTemplate,
    [payload.Id]: payload,
  },
  lastCreatedTemplate: payload,
});

export const createListTemplateClear = state => ({
  ...state,
  isLoading: false,
  createdTemplate: {},
});

export const createListTemplateFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const createListTemplateItemRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const createListTemplateItemSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  createdTemplateItems: {
    ...state.createdTemplateItems,
    [payload.Id]: payload,
  },
});

export const createListTemplateItemFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const searchListTemplateRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const searchListTemplateSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  searchResults: payload,
});

export const searchListTemplateFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const checkMatchImportListRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const checkMatchImportListSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  checkMatch: payload,
});

export const checkMatchImportListFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getUnitTaskStatusRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getUnitTaskStatusSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  listTaskStatus: payload,
});

export const getUnitTaskStatusFailure = (state, { payload }) => ({
  ...state,
  error: payload,
  isLoading: false,
});

export const getUnitListTemplateTypesRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});

export const getUnitListTemplateTypesSuccess = (state, { payload }) => ({
  ...state,
  isLoading: false,
  templateTypesList: payload,
});

export default initialState;
