import styled from 'styled-components';

import { TextareaField } from 'components/Layout/Textarea';

const DialogContainer = styled.div`
  padding: 40px 40px 0;
  text-align: center;
  background-color: ${({ theme }) => theme.primaryActivator};
`;

const FieldBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;

  button {
    display: flex !important;
    align-self: center;
  }
`;

const SecondaryContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const MainTitle = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 20px;
  text-align: center;
  margin: 15px auto;
  padding-bottom: 15px;
`;

const TextField = styled(TextareaField)`
  label {
    left: calc(20% + 10px) !important;
  }

  div {
    width: 60% !important;
    margin: 0 auto !important;
  }

  div > textarea {
    border: 1px solid ${({ theme }) => theme.primaryRed} !important;
    background-color: ${({ theme }) => theme.primaryWhite} !important;
  }
`;

const Textarea = styled(TextField)`
  div {
    width: 100% !important;
  }

  label {
    left: 10px !important;
  }
`;

const FormContainer = styled.div`
  color: ${({ theme }) => theme.primaryBlack} !important;

  form {
    & > div:nth-child(5) {
      justify-content: center;
    }

    & div > button:first-child {
      margin-right: 10px !important;
    }

    & > div:last-child {
      padding: 20px 0 !important;
    }
  }
`;

const BoldText = styled.span`
  font-weight: 700;
`;

const ErrorContainer = styled.div`
  color: ${({ theme }) => theme.primaryRed};
  font-size: 14px;
`;

const ConfirmationContainer = styled.div``;

const ConfirmationWarning = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.primaryBlack};
  font-weight: 300;
  margin: 15px 0;
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.primaryWhite};
  border: 1px solid ${({ theme }) => theme.darkGrey};
  border-radius: 20px;
  padding: 10px 20px;
  margin-bottom: 20px;
`;

const Name = styled.h2`
  color: ${({ theme }) => theme.mainRed};
  text-align: left;
  font-weight: 400;
`;

export {
  DialogContainer,
  FieldBlock,
  SecondaryContainer,
  MainTitle,
  TextField,
  FormContainer,
  BoldText,
  ErrorContainer,
  ConfirmationContainer,
  ConfirmationWarning,
  Wrapper,
  Name,
  Textarea,
};
