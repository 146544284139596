import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { exportSitePdf } from 'http/exportPdf';
import { actions as unitActions } from 'redux/unit';
import { actions as unitsActions } from 'redux/units';
import { actions as authActions } from 'redux/auth';
import { actions as listTemplatesActions } from 'redux/listTemplates';
import { actions as exportPdfActions } from 'redux/exportPdf';
import { openErrorDialog } from 'redux/errorHandler';

import TableList from 'components/TableList';
import { AddIcon, BackIcon, DeleteIcon, ExportQRIcon, ImportIcon, LowPriorityIcon } from 'components/Layout/Icons';
import { ButtonIcon } from 'components/Layout/Buttons';
import RightControls from 'components/SimpleList/ListItem/RightControls';
import Subheader from 'components/Subheader';
import PDFDialog from 'components/Dialogs/PdfDialog';
import PreviewDuplicateDialog from 'components/Dialogs/DuplicateDialog';

import DuplicateDialog from './DuplicateDialog';
import DragFullListDialog from './DragFullListDialog';
import AddEditUnitDialog from '../AddEditUnitDialog';
import ListExchangeUploadDialog from './ListExchangeUploadDialog';

import theme from 'theme';
import { tableConfigs } from './configs';

import * as S from './styled';

const UnitsList = () => {
  const allowSubmitToListExchange = useSelector(state => state.auth.user.allowSubmitToListExchange);
  const createdUnitId = useSelector(state => state.units.createdUnitId);
  const error = useSelector(state => state.units.error);
  const isUnitsLoading = useSelector(state => state.units.isUnitsLoading);
  const pdfList = useSelector(state => state.exportPdf.list);
  const selectedSite = useSelector(state => state.auth.selectedSite);
  const tenantIsOEM = useSelector(state => state.auth.user.tenantIsOEM);
  const unitsList = useSelector(state => state.units.unitsList);
  const unitWithItemsList = useSelector(state => state.units.unitWithItemsList);
  const userType = useSelector(state => state.auth.user.userType);
  const IsGeneratedDefault = useSelector(state =>
    state.unit.unitData.Barcode ? state.unit.unitData.Barcode.IsGeneratedDefault : false,
  );
  const unit = useSelector(state => state.unit.unitData);
  const templateTypesList = useSelector(state => state.listTemplates.templateTypesList);

  const [addUnitDialogOpened, setAddUnitDialogOpened] = useState(false);
  const [editUnitDialogOpened, setEditUnitDialogOpened] = useState(false);
  const [isDefaultBarcode, setIsDefaultBarcode] = useState(IsGeneratedDefault);
  const [dragFullListDialogOpened, setDragFullListDialogOpened] = useState(false);
  const [duplicateDialogOpened, setDuplicateDialogOpened] = useState(false);
  const [pdfDialogOpened, setPdfDialogOpened] = useState(false);
  const [isListExchangeUploadDialogOpen, setIsListExchangeUploadDialogOpen] = useState(false);
  const [qrCodeSize, setQrCodeSize] = useState(2.0);
  const [searchData, setSearchData] = useState('');
  const [unitToDuplicate, setUnitToDuplicate] = useState(null);
  const [unitWithPDF, setUnitWithPDF] = useState(null);
  const [isListDeleteModalOpened, setIsListDeleteModalOpened] = useState(false);
  const [isChecklistsDeletedInfoModalOpened, setIsChecklistsDeletedInfoModalOpened] = useState(false);
  const [isTasksDeletedInfoModalOpened, setIsTasksDeletedInfoModalOpened] = useState(false);
  const [isPreviewDuplicateDialogVisible, setIsPreviewDuplicateDialogVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const getPdf = pdf => {
    if (unitWithPDF) {
      exportSitePdf({
        siteId: unitWithPDF.SiteId,
        id: pdf.Id,
      });
    } else {
      exportSitePdf(pdf.Id);
    }
  };

  const deletePdf = pdf => {
    if (unitWithPDF) {
      dispatch(
        exportPdfActions.deletePdfFileRequest({
          siteId: unitWithPDF.SiteId,
          id: pdf.Id,
        }),
      );
    } else {
      dispatch(
        exportPdfActions.deletePdfFileRequest({
          allSite: true,
          id: pdf.Id,
        }),
      );
    }
  };

  const onDeleteUnitApprove = () => {
    setEditUnitDialogOpened(false);
    setIsListDeleteModalOpened(false);
    setIsTasksDeletedInfoModalOpened(false);

    dispatch(unitActions.deleteUnitRequest(unit.Id));
  };

  const onDeleteUnit = () => {
    dispatch(listTemplatesActions.getUnitTaskStatusRequest(unit.Id));
    setIsListDeleteModalOpened(true);
  };

  const setDefaultBarcode = value => setIsDefaultBarcode(value);

  const closeAddUnitDialog = () => setAddUnitDialogOpened(false);

  const closePdfDialog = () => {
    setPdfDialogOpened(false);
    setUnitWithPDF(null);
  };

  const closeDragFullListDialog = () => setDragFullListDialogOpened(false);

  const openListExchangeUploadDialog = () => setIsListExchangeUploadDialogOpen(true);

  const closeListExchangeUploadDialog = () => setIsListExchangeUploadDialogOpen(false);

  // eslint-disable-next-line no-shadow
  const openPreviewDuplicateDialog = unitToDuplicate => {
    setIsPreviewDuplicateDialogVisible(true);
    setUnitToDuplicate(unitToDuplicate);
  };

  const closePreviewDuplicateDialog = () => setIsPreviewDuplicateDialogVisible(false);

  const openDuplicateDialog = () => {
    setDuplicateDialogOpened(true);
    closePreviewDuplicateDialog();
  };

  const closeDuplicateDialog = () => {
    setDuplicateDialogOpened(false);
    setUnitToDuplicate(null);
  };

  const duplicateUnit = unitData => {
    closeDuplicateDialog();
    dispatch(unitsActions.duplicateUnitRequest(unitData));
  };

  const goToUnitDetails = item => {
    history.push(`/units/items/${item.Id}`);
  };

  const openAddUnitDialog = () => {
    dispatch(exportPdfActions.refresh());
    setAddUnitDialogOpened(true);
  };

  const openEditUnitDialog = () => setEditUnitDialogOpened(true);

  const closeEditUnitDialog = () => {
    setEditUnitDialogOpened(false);
    setIsDefaultBarcode(IsGeneratedDefault);
  };

  const handleClickChecklistDeletedInfoModal = () => {
    setIsChecklistsDeletedInfoModalOpened(false);
    setIsTasksDeletedInfoModalOpened(true);
  };

  const handleCloseTasksDeletedInfoModal = () => setIsTasksDeletedInfoModalOpened(false);

  const handleCloseChecklistsDeletedInfoModal = () => setIsChecklistsDeletedInfoModalOpened(false);

  const handleClickListDeleteModal = () => setIsChecklistsDeletedInfoModalOpened(true);

  const handleCloseListDeleteModal = () => setIsListDeleteModalOpened(false);

  // eslint-disable-next-line no-shadow
  const openPdfDialog = unit => {
    if (unit && unit.Id) {
      dispatch(
        exportPdfActions.getPdfListRequest({
          siteId: unit.SiteId,
          unitId: unit.Id,
        }),
      );
      setUnitWithPDF(unit);
    } else {
      dispatch(exportPdfActions.getPdfListRequest({ siteId: unit }));
    }
    setPdfDialogOpened(true);
  };

  const openDragFullListDialog = siteId => {
    dispatch(unitsActions.getUnitsWithItemsRequest({ siteId }));
    setDragFullListDialogOpened(true);
  };

  const saveNewUnit = items => dispatch(unitsActions.updateItemsListRequest(items));

  const changeSize = (event, index, value) => setQrCodeSize(value);

  const backHome = () => history.push('/home');

  const createQrCode = (size, siteId) => {
    if (unitWithPDF) {
      dispatch(
        exportPdfActions.createPdfFileRequest({
          siteId: unitWithPDF.SiteId,
          unitId: unitWithPDF.Id,
          size,
        }),
      );
    } else {
      dispatch(
        exportPdfActions.createPdfFileRequest({
          siteId,
          size,
        }),
      );
    }
  };

  const itemsForUnitReorder = items =>
    items.map(item => ({
      id: item.id,
      content: item.itemName,
      sequence: item.sequence,
    }));

  const unitsWithItems = list =>
    list.map(item => ({
      id: item.UnitId,
      content: item.UnitName,
      items: itemsForUnitReorder(item.Items),
    }));

  const searchInList = event => setSearchData(event.target.value);

  const filterList = item => item.Name.toLowerCase().includes(searchData.toLowerCase());

  // eslint-disable-next-line no-shadow
  const submitUnit = unit => {
    const selected = templateTypesList.find(t => t.Name === unit.TemplateTypeName);

    dispatch(unitsActions.addUnitRequest({ ...unit, TemplateTypeId: selected?.Id }));
  };

  const submitEditUnit = values => {
    const selected = templateTypesList.find(t => t.Name === values.TemplateTypeName);

    dispatch(
      unitActions.editUnitRequest({
        params: {
          ...values,
          TemplateTypeId: selected?.Id,
          Barcode: {
            ...values.Barcode,
            IsGeneratedDefault: isDefaultBarcode,
          },
        },
        closeEditUnitDialog,
      }),
    );
  };

  const rightButtons = [
    {
      icon: <LowPriorityIcon />,
      handler: () => openDragFullListDialog(selectedSite),
      hint: 'Sequence setup',
    },
    {
      icon: <ExportQRIcon />,
      handler: () => openPdfDialog(selectedSite),
      hint: "QR pdf's",
    },
    {
      icon: <AddIcon />,
      handler: openAddUnitDialog,
      hint: 'Add',
    },
  ];

  const rightControls = item => (
    <div>
      <ButtonIcon onClick={() => deletePdf(item)} tooltip="Delete">
        <DeleteIcon />
      </ButtonIcon>
      <ButtonIcon onClick={() => getPdf(item)} tooltip="Get">
        <ImportIcon />
      </ButtonIcon>
    </div>
  );

  const mapList = item => ({
    ...item,
    ModelNumber: (
      <>
        <S.BoldText>Model</S.BoldText>: {item.ModelNumber}
      </>
    ),
    Items: (
      <>
        <S.BoldText>Items</S.BoldText>: {item.ItemCount}
      </>
    ),
  });

  const onRowClick = item => {
    dispatch(unitActions.fetchUnitRequest(item.Id));

    openEditUnitDialog();
  };

  const renderRightControllCell = item => {
    const handleClick = e => {
      e.stopPropagation();
      goToUnitDetails(item);
    };

    const onUploadButtonClick = e => {
      e.stopPropagation();
      dispatch(unitActions.fetchUnitRequest(item.Id));
      openListExchangeUploadDialog();
    };

    return (
      <S.TableCell>
        <RightControls
          unit={item}
          userType={userType}
          tenantIsOEM={tenantIsOEM}
          allowSubmitToListExchange={allowSubmitToListExchange}
          onUploadButtonClick={onUploadButtonClick}
          openPdfDialog={openPdfDialog}
          openDuplicateDialog={openPreviewDuplicateDialog}
        />
        <ButtonIcon onClick={handleClick} tooltip="List template Details" tooltipStyles={{ marginTop: -14 }}>
          <S.IconForward />
        </ButtonIcon>
      </S.TableCell>
    );
  };

  useEffect(() => {
    dispatch(unitsActions.unitsListRequest({ filter: { SiteID: selectedSite } }));
    dispatch(authActions.fetchUserSitesRequest());
    dispatch(listTemplatesActions.getUnitListTemplateTypesRequest());

    return () => {
      dispatch(exportPdfActions.refresh());
    };
  }, [dispatch, selectedSite]);

  useEffect(() => {
    if (error) {
      dispatch(openErrorDialog(error || 'An error occured while saving the Unit', 'Error'));
    }

    if (createdUnitId) {
      closeAddUnitDialog();
    }
  }, [error, createdUnitId, dispatch]);

  return (
    <>
      <Subheader
        key="Units subheader"
        title="List Templates"
        isSiteOnLeftSide
        isSearch
        searchData={searchData}
        searchInList={searchInList}
        leftButtons={[
          {
            icon: <BackIcon />,
            handler: backHome,
            hint: 'Back',
          },
        ]}
        rightButtons={rightButtons}
      />
      {!unitsList?.length ? (
        <S.EmptyList>
          <S.EmptyListText>There are no List Templates available.</S.EmptyListText>
        </S.EmptyList>
      ) : (
        <S.TableContainer>
          <TableList
            list={unitsList.map(mapList).filter(filterList)}
            tableColumns={tableConfigs}
            renderRightControllCell={renderRightControllCell}
            onRowClick={onRowClick}
          />
        </S.TableContainer>
      )}
      <PreviewDuplicateDialog
        open={isPreviewDuplicateDialogVisible}
        name={unitToDuplicate?.Name}
        handleClose={closePreviewDuplicateDialog}
        type="unit"
        onPositiveHandler={openDuplicateDialog}
      />
      <DuplicateDialog
        key="Duplicate Dialog"
        handleClose={closeDuplicateDialog}
        unitToDuplicate={unitToDuplicate}
        onSubmit={duplicateUnit}
        open={duplicateDialogOpened}
        customColor={theme.primaryGreen}
      />
      {dragFullListDialogOpened && (
        <DragFullListDialog
          key="DragFullList Dialog"
          handleClose={closeDragFullListDialog}
          units={unitsWithItems(unitWithItemsList)}
          isLoading={isUnitsLoading}
          onSubmit={saveNewUnit}
          open={dragFullListDialogOpened}
        />
      )}
      <AddEditUnitDialog
        key="Add Unit Dialog"
        handleClose={closeAddUnitDialog}
        onSubmit={submitUnit}
        open={addUnitDialogOpened}
        title="Add List Template"
        isNewSubmitButton
        withSubmit={false}
        hasPaddingBottom={false}
        withoutTopBorder
        hasPadding
        templateTypesList={templateTypesList}
      />
      <AddEditUnitDialog
        key="Edit Unit Dialog"
        isDefaultBarcode={isDefaultBarcode}
        setDefaultBarcode={setDefaultBarcode}
        handleClose={closeEditUnitDialog}
        handleDelete={onDeleteUnit}
        onSubmit={submitEditUnit}
        open={editUnitDialogOpened}
        title="Edit Template Info"
        unit={unit}
        isNewDeleteButton
        isNewSubmitButton
        withSubmit={false}
        hasPadding
        gapValue="20px"
        hasPaddingBottom={false}
        withoutTopBorder
        isListDeleteModalOpened={isListDeleteModalOpened}
        isChecklistsDeletedInfoModalOpened={isChecklistsDeletedInfoModalOpened}
        isTasksDeletedInfoModalOpened={isTasksDeletedInfoModalOpened}
        history={history}
        handleClickChecklistDeletedInfoModal={handleClickChecklistDeletedInfoModal}
        handleCloseTasksDeletedInfoModal={handleCloseTasksDeletedInfoModal}
        handleCloseChecklistsDeletedInfoModal={handleCloseChecklistsDeletedInfoModal}
        handleClickListDeleteModal={handleClickListDeleteModal}
        handleCloseListDeleteModal={handleCloseListDeleteModal}
        onDeleteUnitApprove={onDeleteUnitApprove}
        templateTypesList={templateTypesList}
      />
      <PDFDialog
        key="PDF"
        title="PDF"
        handleClose={closePdfDialog}
        pdfList={pdfList}
        qrCodeSize={qrCodeSize}
        changeSize={changeSize}
        isSearch={false}
        createQR
        handleClick={() => createQrCode(qrCodeSize, selectedSite)}
        open={pdfDialogOpened}
        rightControls={rightControls}
      />
      <ListExchangeUploadDialog
        unit={unit}
        open={isListExchangeUploadDialogOpen}
        handleClose={closeListExchangeUploadDialog}
      />
    </>
  );
};

export default UnitsList;
