import { ignoreRules } from './constants';

export const initialRule = {
  optionName: '',
  optionConstraint: 0,
  optionValue: '',
  matchOperator: 0,
};

const checkForIgnoreRules = item => ignoreRules.includes(item.split('-')[1]);

export const createId = (flag = '') => `${flag}${new Date().getTime()}`;

const generateRulesFromArrayOfKeys = (localValues, globalValues) => {
  const optionNames = localValues.filter(item => item.includes('optionName'));
  const optionConstraints = localValues.filter(item => item.includes('constraint'));
  const optionValues = localValues.filter(item => item.includes('optionValue'));
  const matchOperator = localValues.find(item => item.includes('match'));

  const ignoreRule = localValues.find(item => {
    if (checkForIgnoreRules(item) && globalValues[item]) {
      return true;
    }

    return false;
  });

  if (ignoreRule) {
    const item = {};

    item.OptionName = ignoreRule.split('-')[1];
    item.OptionConstraint = -1;
    item.OptionValue = '';
    item.MatchOperator = -1;

    return [item];
  }

  const rules = optionNames.map((optionNameKey, index) => {
    const item = {};

    item.OptionName = globalValues[optionNameKey];
    item.OptionConstraint = parseInt(globalValues[optionConstraints[index]], 10);
    item.OptionValue = globalValues[optionValues[index]];
    item.MatchOperator = parseInt(globalValues[matchOperator], 10);

    return item;
  });

  return rules;
};

export const generateUnitRules = (values, rules) => {
  const unitValues = Object.keys(values).filter(item => item.includes('unit'));
  const updatedUnitValues = unitValues
    .map(item => {
      if (item === 'unit-UnitIgnore' || item === 'match-unit' || rules.find(rule => rule.id === item.split('-')[1])) {
        return null;
      }
      return item;
    })
    .filter(item => item !== null);

  updatedUnitValues.forEach(item => delete values[item]);

  const fixedUnitValues = unitValues.filter(item => !updatedUnitValues.find(newItem => newItem === item));
  const data = {
    UnitRules: generateRulesFromArrayOfKeys(fixedUnitValues, values),
  };
  return data;
};

export const generateItemRules = (values, rules) => {
  const itemValues = Object.keys(values).filter(item => item.includes('item'));

  const updatedItemValues = itemValues
    .map(item => {
      if (item === 'item-ItemIgnore' || item === 'match-item' || rules.find(rule => rule.id === item.split('-')[1])) {
        return null;
      }
      return item;
    })
    .filter(item => item !== null);

  updatedItemValues.forEach(item => delete values[item]);

  const fixedItemValues = itemValues.filter(item => !updatedItemValues.find(newItem => newItem === item));

  const data = {
    ItemRules: generateRulesFromArrayOfKeys(fixedItemValues, values),
  };
  return data;
};

export const generateTaskManagedByRules = (values, rules) => {
  const taskManagedByValues = Object.keys(values).filter(item => item.includes('taskManagedBy'));

  const updatedTaskManagedByValues = taskManagedByValues
    .map(item => {
      if (
        item === 'taskManagedBy-TaskManagedByIgnore' ||
        item === 'match-taskManagedBy' ||
        rules.find(rule => rule.id === item.split('-')[1])
      ) {
        return null;
      }
      return item;
    })
    .filter(item => item !== null);

  updatedTaskManagedByValues.forEach(item => delete values[item]);

  const fixedTaskManagedByValues = taskManagedByValues.filter(
    item => !updatedTaskManagedByValues.find(newItem => newItem === item),
  );

  const data = {
    TaskManagedByRules: generateRulesFromArrayOfKeys(fixedTaskManagedByValues, values),
  };

  return data;
};

export const generateTaskCompletedByRules = (values, rules) => {
  const taskCompletedByValues = Object.keys(values).filter(item => item.includes('taskCompletedBy'));

  const updatedTaskCompletedByValues = taskCompletedByValues
    .map(item => {
      if (
        item === 'taskCompletedBy-TaskCompletedByIgnore' ||
        item === 'match-taskCompletedBy' ||
        rules.find(rule => rule.id === item.split('-')[1])
      ) {
        return null;
      }
      return item;
    })
    .filter(item => item !== null);

  updatedTaskCompletedByValues.forEach(item => delete values[item]);

  const fixedTaskCompletedByValues = taskCompletedByValues.filter(
    item => !updatedTaskCompletedByValues.find(newItem => newItem === item),
  );

  const data = {
    TaskCompletedByRules: generateRulesFromArrayOfKeys(fixedTaskCompletedByValues, values),
  };
  return data;
};

export const checkOptions = options => {
  if (options.length === 0) {
    return false;
  }
  if (options[0].MatchOperator === -1) {
    return true;
  }
  const res = options.every(item => {
    if (
      !item.OptionName ||
      (!item.OptionValue && item.OptionValue !== 0) ||
      (!item.OptionConstraint && item.OptionConstraint !== 0)
    ) {
      return false;
    }
    return true;
  });
  return res;
};

export const generateInitialRules = ({ rules, flag }) => {
  if (!rules) {
    return {
      name: flag,
      match: 0,
      rules: [{ id: createId(flag), name: flag, ...initialRule }],
      isRulesIgnored: true,
    };
  }

  if (rules[0]?.MatchOperator === -1) {
    return {
      name: flag,
      match: 0,
      rules: [{ id: createId(flag), name: flag, ...initialRule }],
      isRulesIgnored: true,
    };
  }

  return {
    name: flag,
    match: rules[0]?.MatchOperator,
    rules: rules.map((item, index) => {
      const tmpId = `${createId(flag)}${index}`;
      const fieldNames = [`optionName-${tmpId}`, `constraint-${tmpId}`, `optionValue-${tmpId}`];
      return {
        id: tmpId,
        name: flag,
        [fieldNames[0]]: item.OptionName,
        [fieldNames[1]]: item.OptionConstraint,
        [fieldNames[2]]: item.OptionValue,
        matchOperator: item.MatchOperator,
      };
    }),
    isRulesIgnored: false,
  };
};

export const initialTitle = {
  unit: 'Units',
  item: 'Items',
  taskManagedBy: 'Task Managed By',
  taskCompletedBy: 'Task Completed By',
};
