const initialState = {
  dataTenants: [],
  dataTaskTypes: [],
  error: '',
  isLoading: false,
  isActiveAll: false,
  searchField: '',
  selectedTaskType: null,
};

export const getTenantsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});
export const getTenantsSuccess = (state, { payload }) => ({
  ...state,
  dataTenants: payload,
  isLoading: false,
});
export const getTenantsFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  isLoading: false,
});

export const updateTenantsRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});
export const updateTenantsSuccess = state => ({
  ...state,
  isLoading: false,
});
export const updateTenantsFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  isLoading: false,
});

export const updateActiveAllFlag = (state, { payload }) => ({
  ...state,
  isActiveAll: payload.isActiveAll,
  dataTenants: payload.data,
});

export const editData = (state, { payload }) => ({
  ...state,
  dataTenants: payload,
});

export const updateSearchField = (state, { payload }) => ({
  ...state,
  searchField: payload,
});

export const getTaskTypesRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});
export const getTaskTypesSuccess = (state, { payload }) => ({
  ...state,
  dataTaskTypes: payload,
  isLoading: false,
});
export const getTaskTypesFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  isLoading: false,
});

export const putTaskTypesRequest = state => ({
  ...state,
  error: '',
  isLoading: true,
});
export const putTaskTypesSuccess = (state, { payload }) => ({
  ...state,
  dataTaskTypes: state.dataTaskTypes.map(elem =>
    elem.Id === payload.entity.Id ? { ...elem, Value: payload.entity.Value } : elem,
  ),
  isLoading: false,
});
export const putTaskTypesFailure = (state, { payload }) => ({
  ...state,
  error: payload.message,
  isLoading: false,
});

export const setSelectedTaskTypes = (state, { payload }) => ({
  ...state,
  selectedTaskType: payload,
});

export const setGlobalSettingsLoading = (state, { payload }) => ({
  ...state,
  isLoading: false,
  error: payload,
});

export default initialState;
