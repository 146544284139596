import styled, { FlattenInterpolation, ThemeProps } from 'styled-components';

const TextareaField = styled.textarea<{ styles?: FlattenInterpolation<ThemeProps<any>>[]; hasError: boolean }>`
  width: 100%;
  min-height: 100px;
  padding: 12px 20px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.primaryWhite};
  font-size: 16px;
  font-family: Roboto, sans-serif;
  color: #00001e;
  resize: none;
  border: 1px solid ${({ theme, hasError }) => (hasError ? theme.primaryRed : theme.fieldsBorderColor)};
  border-radius: 10px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'text')} ${({ styles }) => styles};

  &::placeholder {
    color: #b2b2b2;
  }
`;

const ErrorMessage = styled.div`
  margin: 10px 0;
  text-align: center;
  color: ${({ theme }) => theme.mainRed};
`;

export { TextareaField, ErrorMessage };
