import { handleActions, createActions } from 'redux-actions';

import initialState, * as handlers from './handlers';

export const actions = createActions({
  GET_TENANTS_REQUEST: undefined,
  GET_TENANTS_SUCCESS: undefined,
  GET_TENANTS_FAILURE: undefined,

  UPDATE_ACTIVE_ALL_FLAG: undefined,

  EDIT_DATA: undefined,

  UPDATE_TENANTS_REQUEST: undefined,
  UPDATE_TENANTS_SUCCESS: undefined,
  UPDATE_TENANTS_FAILURE: undefined,

  UPDATE_SEARCH_FIELD: undefined,

  GET_TASK_TYPES_REQUEST: undefined,
  GET_TASK_TYPES_SUCCESS: undefined,
  GET_TASK_TYPES_FAILURE: undefined,

  PUT_TASK_TYPES_REQUEST: undefined,
  PUT_TASK_TYPES_SUCCESS: undefined,
  PUT_TASK_TYPES_FAILURE: undefined,

  SET_SELECTED_TASK_TYPES: undefined,

  SET_GLOBAL_SETTINGS_LOADING: undefined,
});

const reducer = handleActions(
  new Map([
    [actions.getTenantsRequest, handlers.getTenantsRequest],
    [actions.getTenantsSuccess, handlers.getTenantsSuccess],
    [actions.getTenantsFailure, handlers.getTenantsFailure],

    [actions.updateTenantsRequest, handlers.updateTenantsRequest],
    [actions.updateTenantsSuccess, handlers.updateTenantsSuccess],
    [actions.updateTenantsFailure, handlers.updateTenantsFailure],

    [actions.updateActiveAllFlag, handlers.updateActiveAllFlag],

    [actions.editData, handlers.editData],

    [actions.updateSearchField, handlers.updateSearchField],

    [actions.getTaskTypesRequest, handlers.getTaskTypesRequest],
    [actions.getTaskTypesSuccess, handlers.getTaskTypesSuccess],
    [actions.getTaskTypesFailure, handlers.getTaskTypesFailure],

    [actions.putTaskTypesRequest, handlers.putTaskTypesRequest],
    [actions.putTaskTypesSuccess, handlers.putTaskTypesSuccess],
    [actions.putTaskTypesFailure, handlers.putTaskTypesFailure],

    [actions.setSelectedTaskTypes, handlers.setSelectedTaskTypes],
    [actions.setGlobalSettingsLoading, handlers.setGlobalSettingsLoading],
  ]),
  initialState,
);

export default reducer;
