import styled from 'styled-components';

const Section = styled.div`
  border: 1px solid ${({ theme }) => theme.primaryGrey};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
  margin-bottom: 30px;
  text-align: start;
  width: 100%;
`;

const SectionHeader = styled.div`
  background-color: ${({ theme }) => theme.textGray};
  color: ${({ theme }) => theme.primaryWhite};
  padding: 10px 15px;
  text-transform: capitalize;
`;

const SectionBody = styled.div`
  padding: 30px 30px 15px;
`;

export { Section, SectionHeader, SectionBody };
