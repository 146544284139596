import { call, put, takeEvery, select } from 'redux-saga/effects';

import { getTenants, updateTenants } from 'http/tenants';
import { getTaskTypes, putTaskTypes } from 'http/globalSettings';

import { ERROR_DIALOG_OPENED } from 'redux/errorHandler';
import { actions } from './index';

function* getTenantsRequest({ payload }) {
  try {
    const data = yield call(getTenants, { payload });
    yield put(actions.getTenantsSuccess(data));
  } catch (error) {
    yield put(actions.getTenantsFailure(error));
  }
}

function* updateTenantsRequest({ payload }) {
  try {
    const { data } = yield select(({ globalSettings }) => globalSettings.tenants);
    yield call(updateTenants, { data });
    payload.onCloseDialog();
    yield put(actions.updateTenantsSuccess());
  } catch (error) {
    yield put(actions.updateTenantsFailure, payload(error));
    yield put({
      type: ERROR_DIALOG_OPENED,
      payload: {
        title: 'Error due to data update',
        message: error.message,
      },
    });
  }
}

function* getTaskTypesRequest() {
  try {
    const {
      data: {
        entity: { Results },
      },
    } = yield call(getTaskTypes);
    yield put(actions.getTaskTypesSuccess(Results));
  } catch (error) {
    yield put(actions.getTaskTypesFailure(error));
  }
}

function* putTaskTypesRequest({ payload }) {
  try {
    const {
      data: { entity },
    } = yield call(putTaskTypes, payload.id, payload.value);
    yield put(actions.putTaskTypesSuccess({ entity }));
  } catch (error) {
    yield put(actions.putTaskTypesFailure(error));
  }
}

const globalSettingsSagas = [
  takeEvery(actions.getTenantsRequest, getTenantsRequest),
  takeEvery(actions.updateTenantsRequest, updateTenantsRequest),
  takeEvery(actions.getTaskTypesRequest, getTaskTypesRequest),
  takeEvery(actions.putTaskTypesRequest, putTaskTypesRequest),
];

export default globalSettingsSagas;
