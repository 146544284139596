import React from 'react';
import PropTypes from 'prop-types';

import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';

import theme from 'theme';

const styles = {
  radioButton: {
    padding: '5px 0px',
    display: 'flex',
  },
};

const RadioGroup = ({ input, meta, style }) => (
  <RadioButtonGroup
    name="datetime-Type"
    valueSelected={input.value}
    defaultSelected={1}
    onChange={(event, data) => input.onChange(data)}
    errorText={meta.visited || meta.touched ? meta.error : ''}
    style={style}
  >
    <RadioButton
      value={2}
      label="Month"
      iconStyle={{ fill: theme.primaryScheduler, marginRight: '5px' }}
      labelStyle={{ textAlign: 'left' }}
      style={styles.radioButton}
    />
    <RadioButton
      value={1}
      label="Week (Mon-Sun)"
      iconStyle={{ fill: theme.primaryScheduler, marginRight: '5px' }}
      labelStyle={{ textAlign: 'left', width: '100%' }}
      style={styles.radioButton}
    />
  </RadioButtonGroup>
);

RadioGroup.propTypes = {
  style: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export default RadioGroup;
