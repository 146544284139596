import moment from 'moment';

import theme from '../theme';
import { USER_TYPES } from './enums';
import { HOME_INSTRUCTIONS } from './instructions';
import {
  ActivatorIcon,
  AttentionTasksIcon,
  DocumentsModuleIcon,
  ListsModuleIcon,
  PartsIcon,
  PeopleModuleIcon,
  QueueModuleIcon,
  SchedulerModuleIcon,
  SitesModuleIcon,
  TasksModuleIcon,
  TenantsModuleIcon,
  SettingsIcon,
  ExchangeModuleIcon,
  HealthIcon,
  OfflineBoltWhite,
  TeamsWhite,
  CommitUnitIcon,
  TutorialsIcon,
  WifiIcon,
  MdOfflineBoltIcon,
  HiBoltIcon,
  WarehouseIcon,
  ShoppingBasketIcon,
  InsightsIcon,
} from 'components/Layout/Icons';

export const rangeStart = () => moment().format('YYYY-MM-DD');

const GLOBAL_MODULES_DATA = [
  {
    name: 'Health',
    color: theme.primaryHealth,
    icon: HealthIcon,
    textColor: theme.textGray,
    path: '/health',
    userTypes: [USER_TYPES.SystemAdministrator, USER_TYPES.DashboardUser],
  },
];

const ROW_MODULES_DATA = [
  {
    name: 'Sites',
    color: theme.primarySites,
    icon: SitesModuleIcon,
    path: '/sites',
    userTypes: [USER_TYPES.SystemAdministrator, USER_TYPES.OEMAdministrator],
  },
  {
    name: 'People',
    color: theme.primaryPeople,
    icon: PeopleModuleIcon,
    path: '/people',
    userTypes: [USER_TYPES.Manager, USER_TYPES.SystemAdministrator, USER_TYPES.OEMAdministrator],
  },
  {
    name: 'Parts M',
    color: theme.primaryParts,
    icon: PartsIcon,
    path: '/parts',
    userTypes: [USER_TYPES.SystemAdministrator],
  },
  {
    name: 'Shortcuts',
    color: theme.mainRed,
    icon: OfflineBoltWhite,
    path: '/shortcuts',
    userTypes: [USER_TYPES.Manager, USER_TYPES.SystemAdministrator],
  },
  {
    name: 'Settings',
    color: theme.primarySettings,
    icon: SettingsIcon,
    path: '/settings',
    userTypes: [USER_TYPES.SystemAdministrator],
  },
  {
    name: 'Docs',
    color: theme.primaryDocuments,
    icon: DocumentsModuleIcon,
    path: '/documents',
    userTypes: [USER_TYPES.Manager, USER_TYPES.SystemAdministrator],
  },
];

const COLUMN_MODULES_DATA = [
  {
    name: 'Teams',
    color: theme.primaryBlue,
    icon: TeamsWhite,
    path: '/teams',
    userTypes: [USER_TYPES.SystemAdministrator],
  },
  {
    name: 'List Templates',
    color: theme.primaryLists,
    icon: ListsModuleIcon,
    path: '/units',
    userTypes: [USER_TYPES.Manager, USER_TYPES.SystemAdministrator, USER_TYPES.OEMAdministrator, USER_TYPES.OEMManager],
  },
  {
    name: 'Parts',
    color: theme.primaryParts,
    icon: PartsIcon,
    path: 'siteparts',
    userTypes: [USER_TYPES.Manager, USER_TYPES.SystemAdministrator],
  },
  {
    name: 'Scheduler',
    color: theme.primaryScheduler,
    icon: SchedulerModuleIcon,
    path: '/scheduler',
    userTypes: [USER_TYPES.Manager, USER_TYPES.SystemAdministrator],
  },
  {
    name: 'Tasks',
    color: theme.primaryTasks,
    icon: TasksModuleIcon,
    path: '/tasks',
    userTypes: [USER_TYPES.TaskSpecialist, USER_TYPES.Manager, USER_TYPES.SystemAdministrator],
  },
  {
    name: 'Queue',
    color: theme.primaryQueue,
    icon: QueueModuleIcon,
    path: '/queue',
    userTypes: [USER_TYPES.Manager, USER_TYPES.SystemAdministrator],
  },
  {
    name: 'New Tenant Requests',
    color: theme.primaryTenantRequests,
    icon: TenantsModuleIcon,
    path: '/tenantrequests',
    userTypes: [USER_TYPES.SystemController],
  },
  {
    name: 'Upgrade Requests',
    color: theme.upgradeRequestsColor,
    icon: TenantsModuleIcon,
    path: '/upgraderequests',
    userTypes: [USER_TYPES.SystemController],
  },
  {
    name: 'Tenants',
    color: theme.primaryTenants,
    icon: TenantsModuleIcon,
    path: '/tenants',
    userTypes: [USER_TYPES.SystemController],
  },
  {
    name: 'List Exchange',
    color: theme.primaryExchange,
    icon: ExchangeModuleIcon,
    path: '/exchange',
    userTypes: [USER_TYPES.SystemController],
  },
  {
    name: 'Global Settings',
    color: theme.primarySettings,
    icon: SettingsIcon,
    path: '/globalsettings',
    userTypes: [USER_TYPES.SystemController],
  },
  {
    name: 'Tutorials',
    color: theme.primarySettings,
    icon: TutorialsIcon,
    path: '/tutorials',
    userTypes: [USER_TYPES.SystemController],
  },
];

export const ACTIVATOR_MODULES_DATA = {
  name: 'Activator',
  color: theme.primaryHealth,
  icon: ActivatorIcon,
  path: '/activator',
  userTypes: [USER_TYPES.SystemController],
};

export const QUEUE_MODULES_DATA = [
  {
    name: 'Progress',
    icon: QueueModuleIcon,
    path: `/queue/${rangeStart()}/today`,
    userTypes: [USER_TYPES.SystemAdministrator, USER_TYPES.Manager],
    color: theme.primaryQueue,
  },
  {
    name: 'Attention',
    icon: AttentionTasksIcon,
    path: `queue/${rangeStart()}/all`,
    userTypes: [USER_TYPES.SystemAdministrator, USER_TYPES.Manager],
    color: theme.primaryQueue,
  },
  {
    name: 'Triggers',
    icon: WifiIcon,
    path: `/queue/trigger/${rangeStart()}`,
    userTypes: [USER_TYPES.SystemAdministrator, USER_TYPES.Manager],
    color: theme.primaryQueue,
  },
  {
    name: 'Archive',
    icon: CommitUnitIcon,
    path: 'queue/export',
    userTypes: [USER_TYPES.SystemAdministrator, USER_TYPES.Manager],
    color: theme.primaryQueue,
  },
];

export const SCHEDULER_MODULES_DATA = [
  {
    name: 'Checklist Tasks',
    icon: ListsModuleIcon,
    path: `/scheduler/manual`,
    userTypes: [USER_TYPES.SystemAdministrator, USER_TYPES.Manager],
    color: theme.primaryScheduler,
    tooltip: 'Task Total Today',
  },
  {
    name: 'ShortCut Tasks',
    icon: MdOfflineBoltIcon,
    path: `/scheduler/shortcut`,
    userTypes: [USER_TYPES.SystemAdministrator, USER_TYPES.Manager],
    color: theme.primaryScheduler,
    tooltip: 'Task Total Today',
  },
  {
    name: 'QuickTasks (one day)',
    icon: HiBoltIcon,
    path: `/scheduler/quickTask`,
    userTypes: [USER_TYPES.SystemAdministrator, USER_TYPES.Manager],
    color: theme.primaryScheduler,
    tooltip: 'Task Total Today',
  },
];

const PARTS_MODULES_DATA = [
  {
    name: 'Parts in Use',
    icon: PartsIcon,
    path: '/siteparts/list',
    userTypes: [USER_TYPES.SystemAdministrator, USER_TYPES.Manager],
    color: theme.primaryLists,
    iconColor: theme.primaryWhite,
    iconBackgroundColor: theme.primaryBlue,
  },
  {
    name: 'Parts in Inventory',
    icon: WarehouseIcon,
    path: '/siteparts/partsInventory/periods',
    userTypes: [USER_TYPES.SystemAdministrator, USER_TYPES.Manager],
    color: theme.primaryLists,
    iconColor: theme.primaryWhite,
    iconBackgroundColor: theme.primaryBlue,
  },
  {
    name: 'Inventory Release Orders',
    icon: ShoppingBasketIcon,
    path: '/siteparts/partsInventory/releaseOrders',
    userTypes: [USER_TYPES.SystemAdministrator, USER_TYPES.Manager],
    color: theme.primaryLists,
    iconColor: theme.primaryWhite,
    iconBackgroundColor: theme.primaryBlue,
    primaryTooltip: 'Coming Soon',
    isDisableClick: true,
  },
  {
    name: 'Inventory Status',
    icon: InsightsIcon,
    path: '/siteparts/partsInventory/status',
    userTypes: [USER_TYPES.SystemAdministrator, USER_TYPES.Manager],
    color: theme.primaryLists,
    iconColor: theme.primaryWhite,
    iconBackgroundColor: theme.primaryBlue,
    primaryTooltip: 'Coming Soon',
    isDisableClick: true,
  },
];

export const generateModules = sourceModules => {
  const modules = {};

  const getModulesForUserType = userType => [...sourceModules.filter(item => item.userTypes.includes(userType))];

  Object.values(USER_TYPES).forEach(userType => {
    const objectModulesForUserType = {};

    getModulesForUserType(userType).forEach(item => {
      objectModulesForUserType[item.path.substr(1)] = item;
    });

    modules[userType] = objectModulesForUserType;
  });

  return modules;
};

export const GLOBAL_MODULES = generateModules(GLOBAL_MODULES_DATA);
export const ROW_MODULES = generateModules(ROW_MODULES_DATA);
export const COLUMN_MODULES = generateModules(COLUMN_MODULES_DATA);
export const INSTRUCTIONS_MODULES = generateModules(HOME_INSTRUCTIONS);
export const QUEUE_MODULES = generateModules(QUEUE_MODULES_DATA);
export const PARTS_MODULES = generateModules(PARTS_MODULES_DATA);
