import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';

import { actions as newSitesActions } from 'redux/newSites';
import { actions as peopleActions } from 'redux/people';
import { actions as queueActions } from 'redux/queue';

import { Dialog, ActionsContainer, ActionButton } from 'components/Dialogs/v1/Base';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import Loader from 'components/Loader';

import theme from 'theme';

import ReportRules from './ReportRules';
import TaskTiming from './TaskTiming';
import ReportGrouping from './ReportGrouping';
import {
  createId,
  initialRule,
  generateInitialRules,
  generateUnitRules,
  generateItemRules,
  generateTaskManagedByRules,
  generateTaskCompletedByRules,
  initialTitle,
  checkOptions,
} from './ReportRules/helpers';
import { USER_TYPE_OPTIONS, QR, RESPONSE_OPTIONS } from './ReportRules/constants';
import { generateStartDate, generateEndDate } from './TaskTiming/helpers';
import { timePeriod } from './TaskTiming/constants';

import validate from './validator';
import * as S from './styled';

class ReportSetup extends PureComponent {
  static propTypes = {
    actions: PropTypes.shape({
      getJobsListRequest: PropTypes.func.isRequired,
      peopleListRequest: PropTypes.func.isRequired,
      createReportRequest: PropTypes.func.isRequired,
      editReportRequest: PropTypes.func.isRequired,
      deleteReportRequest: PropTypes.func.isRequired,
    }).isRequired,
    jobsList: PropTypes.shape().isRequired,
    handleExit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    site: PropTypes.string.isRequired,
    peopleList: PropTypes.shape().isRequired,
    reportToEdit: PropTypes.shape().isRequired,
    isLoading: PropTypes.bool.isRequired,
  };

  state = {
    unitRules: generateInitialRules({ rules: this.props.reportToEdit.UnitRules, flag: 'unit' }),
    itemRules: generateInitialRules({ rules: this.props.reportToEdit.ItemRules, flag: 'item' }),
    taskManagedByRules: generateInitialRules({
      rules: this.props.reportToEdit.TaskManagedByRules,
      flag: 'taskManagedBy',
    }),
    taskCompletedByRules: generateInitialRules({
      rules: this.props.reportToEdit.TaskCompletedByRules,
      flag: 'taskCompletedBy',
    }),
    archivedTaskTime: { isAllArchivedTasks: true },
    isErrorShown: false,
    isConfirmationDialogOpened: false,
    isErrorDialogOpened: false,
  };

  componentDidMount() {
    this.props.actions.getJobsListRequest();
    this.props.actions.peopleListRequest();
  }

  reloadState = () => {
    this.setState({
      unitRules: generateInitialRules({
        rules: this.props.reportToEdit.UnitRules,
        flag: 'unit',
      }),
      itemRules: generateInitialRules({
        rules: this.props.reportToEdit.ItemRules,
        flag: 'item',
      }),
      taskManagedByRules: generateInitialRules({
        rules: this.props.reportToEdit.TaskManagedByRules,
        flag: 'taskManagedBy',
      }),
      taskCompletedByRules: generateInitialRules({
        rules: this.props.reportToEdit.TaskCompletedByRules,
        flag: 'taskCompletedBy',
      }),
    });
  };

  setInitialValue = () => {
    const { reportToEdit } = this.props;
    const initialValue = {
      'datetime-Archive': true,
      'datetime-Type': 1,
      'datetime-Period': timePeriod[0].value,
      groupBy: '4',
      subGroupBy: '1',
      ReportNotes: reportToEdit.ReportNotes,
      ReportActionPoints: reportToEdit.ReportActionPoints,
    };
    if (reportToEdit.PeriodInterval === 0) {
      initialValue['datetime-Archive'] = false;
    } else {
      initialValue['datetime-Period'] = reportToEdit.PeriodInterval;
      initialValue['datetime-Type'] = reportToEdit.Period;
    }
    const data = { ...initialValue };
    return data;
  };
  componentDidUpdate(prevProps) {
    const { reportToEdit } = this.props;
    if (prevProps.reportToEdit.Id !== reportToEdit.Id) {
      this.reloadState();
    }
  }

  addOption = flag => {
    const stateKey = `${flag}Rules`;
    const currentId = createId(this.state[stateKey].rules[0].name);

    this.setState({
      [stateKey]: {
        ...this.state[stateKey],
        rules: [...this.state[stateKey].rules, { id: currentId, name: flag, ...initialRule }],
      },
    });
  };

  onChangeMatch = (flag, match) => {
    const stateKey = `${flag}Rules`;

    this.setState({
      [stateKey]: { ...this.state[stateKey], match },
    });
  };

  onKeyDownHandler = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  onChangeHandler = (e, value, name, input) => {
    input.onChange(value);
    this.onChangeMatch(name, value);
  };

  onChangeIgnoreRules = flag => value => {
    const stateKey = `${flag}Rules`;

    this.setState({
      [stateKey]: { ...this.state[stateKey], isRulesIgnored: value },
    });
  };

  removeOption = (flag, id) => {
    const stateKey = `${flag}Rules`;

    const rules = this.state[stateKey].rules.filter(rule => rule.id !== id);

    this.setState({
      [stateKey]: { ...this.state[stateKey], rules },
    });
  };

  getOptionValue = value => {
    switch (value) {
      case 'UnitQR':
        return QR;

      case 'TaskCompletedByUserType':
        return USER_TYPE_OPTIONS;

      case 'TaskManagedByJobFunction':
        return [...this.props.jobsList];

      case 'TaskCompletedByJobFunction':
        return [...this.props.jobsList];

      case 'TaskManagedByLastName':
        return [
          ...this.props.peopleList
            .filter(item => item.UserType === 'Manager')
            .map(item => ({ Value: item.LastName, Name: item.LastName })),
        ];

      case 'TaskHasBeenSignedOff':
        return RESPONSE_OPTIONS;
      default:
        return QR;
    }
  };

  onSubmitForm = values => {
    const { actions, handleExit, reportToEdit } = this.props;
    const { unitRules, itemRules, taskManagedByRules, taskCompletedByRules } = this.state;

    if (!values.Name) {
      this.openErrorDialog();
      return;
    }

    const unitValues = generateUnitRules(values, unitRules.rules);
    const itemValues = generateItemRules(values, itemRules.rules);
    const taskManagedByValues = generateTaskManagedByRules(values, taskManagedByRules.rules);
    const taskCompletedByValues = generateTaskCompletedByRules(values, taskCompletedByRules.rules);
    const rulesToSend = {
      ...unitValues,
      ...itemValues,
      ...taskManagedByValues,
      ...taskCompletedByValues,
    };

    const valuesToSend = {
      Name: values.Name,
      ReportNotes: values.ReportNotes,
      ReportActionPoints: values.ReportActionPoints,
      GroupBy: Number(values.groupBy),
      SubGroupBy: Number(values.subGroupBy),
      ...rulesToSend,
    };

    if (values['datetime-Archive']) {
      valuesToSend.StartDate = new Date(Date.now());
      valuesToSend.EndDate = new Date(Date.now());
      valuesToSend.PeriodInterval = values['datetime-Period'];
      valuesToSend.Period = values['datetime-Type'];
    } else {
      valuesToSend.StartDate = generateStartDate(values);
      valuesToSend.EndDate = generateEndDate(values);
      valuesToSend.PeriodInterval = 0;
      valuesToSend.Period = 0;
    }

    if (
      !checkOptions(valuesToSend.UnitRules) ||
      !checkOptions(valuesToSend.ItemRules) ||
      !checkOptions(valuesToSend.TaskManagedByRules) ||
      !checkOptions(valuesToSend.TaskCompletedByRules)
    ) {
      this.setState({ isErrorShown: true });
    } else if (reportToEdit.Name) {
      this.setState({ isErrorShown: false });
      actions.editReportRequest({ ...valuesToSend, Id: reportToEdit.Id });
      handleExit();
      this.reloadState();
    } else {
      this.setState({ isErrorShown: false });
      actions.createReportRequest(valuesToSend);
      handleExit();
      this.reloadState();
    }
  };

  closeModal = () => {
    this.reloadState();
    this.props.handleExit();
  };

  openDeleteDialog = () => {
    this.setState({ isConfirmationDialogOpened: true });
  };

  closeDeleteDialog = () => {
    this.setState({ isConfirmationDialogOpened: false });
  };

  onClickDialogHandler = () => {
    const { actions, handleExit, reportToEdit } = this.props;
    actions.deleteReportRequest({ id: reportToEdit.Id });
    this.setState({ isConfirmationDialogOpened: false });
    this.reloadState();
    handleExit();
  };

  openErrorDialog = () => this.setState({ isErrorDialogOpened: true });

  closeErrorDialog = () => this.setState({ isErrorDialogOpened: false });

  render() {
    const { open, site, reportToEdit, isLoading } = this.props;
    const { isErrorShown, isConfirmationDialogOpened, isErrorDialogOpened } = this.state;

    return (
      <Dialog
        open={open}
        onRequestClose={this.closeModal}
        title={reportToEdit?.Name ? 'Report Edit' : 'New Report Setup'}
        titleColor={theme.secondaryGreen}
      >
        <S.DialogContainer>
          <Loader isLoading={isLoading} size="large" />
          <S.MainTitle>{site && site}</S.MainTitle>
          <S.FormContainer>
            <Form
              onSubmit={this.onSubmitForm}
              validate={validate}
              initialValues={
                reportToEdit?.Name
                  ? this.setInitialValue()
                  : {
                      'datetime-Archive': true,
                      'datetime-Period': timePeriod[0].value,
                      'datetime-Type': 1,
                      groupBy: '4',
                      subGroupBy: '1',
                    }
              }
              render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                  <S.Wrapper>
                    <S.FieldBlock>
                      <Field
                        component={S.TextField}
                        name="Name"
                        floatingLabelText="Report name"
                        initialValue={reportToEdit?.Name}
                      />
                    </S.FieldBlock>

                    <Field
                      component={S.Textarea}
                      name="ReportNotes"
                      rows={4}
                      floatingLabelText={values?.ReportNotes ? 'Observations' : 'Notes / General Observations'}
                    />
                    <Field
                      component={S.Textarea}
                      rows={4}
                      name="ReportActionPoints"
                      floatingLabelText={
                        values?.ReportActionPoints ? 'Action Points' : 'Action Points / Follow Up Notes'
                      }
                    />
                  </S.Wrapper>
                  <S.Wrapper>
                    <TaskTiming values={values} initialValue={reportToEdit} />
                  </S.Wrapper>
                  <S.Wrapper>
                    <S.Name>What Tasks Should Be Included?</S.Name>
                    {['unit', 'item'].map(rule => (
                      <ReportRules
                        key={rule}
                        title={`Include or Exclude Tasks for ${initialTitle[rule]} - Rules`}
                        rules={this.state[`${rule}Rules`]}
                        addOption={this.addOption}
                        removeOption={this.removeOption}
                        onChangeMatch={this.onChangeMatch}
                        isInitialValues={!!reportToEdit.Name}
                        onChangeIgnoreRules={this.onChangeIgnoreRules}
                        values={values}
                        getOptionValue={this.getOptionValue}
                        onKeyDownHandler={this.onKeyDownHandler}
                        onChangeHandler={this.onChangeHandler}
                      />
                    ))}
                  </S.Wrapper>

                  <S.Wrapper>
                    <S.Name>Who’s Tasks Should Be Included?</S.Name>
                    {['taskManagedBy', 'taskCompletedBy'].map(rule => (
                      <ReportRules
                        key={rule}
                        title={`${initialTitle[rule]} - Rules`}
                        rules={this.state[`${rule}Rules`]}
                        addOption={this.addOption}
                        removeOption={this.removeOption}
                        onChangeMatch={this.onChangeMatch}
                        isInitialValues={!!reportToEdit.Name}
                        onChangeIgnoreRules={this.onChangeIgnoreRules}
                        values={values}
                        getOptionValue={this.getOptionValue}
                        onKeyDownHandler={this.onKeyDownHandler}
                        onChangeHandler={this.onChangeHandler}
                      />
                    ))}
                  </S.Wrapper>
                  <S.Wrapper>
                    <S.Name>How should the tasks on this report be organized?</S.Name>
                    <ReportGrouping values={values} isInitialValues={!!reportToEdit.Name} initialValue={reportToEdit} />
                  </S.Wrapper>
                  {isErrorShown && (
                    <S.ErrorContainer>
                      Please add at least one rule or select &quot;Ignore rules&quot; to disable rules
                    </S.ErrorContainer>
                  )}
                  <ActionsContainer>
                    {reportToEdit.Name && (
                      <ActionButton type="button" label="DELETE REPORT" onClick={this.openDeleteDialog} />
                    )}
                    <ActionButton type="submit" isNext label={'SAVE REPORT'} />
                  </ActionsContainer>
                  <ConfirmationDialog
                    label="Yes"
                    maxWidth={'80%'}
                    text={
                      <S.ConfirmationContainer>
                        <S.ConfirmationWarning>
                          This action cannot be undone,
                          <br /> are you sure?
                        </S.ConfirmationWarning>
                      </S.ConfirmationContainer>
                    }
                    open={isConfirmationDialogOpened}
                    onClick={this.onClickDialogHandler}
                    handleClose={this.closeDeleteDialog}
                  />
                  <ConfirmationDialog
                    label="OK"
                    maxWidth={'80%'}
                    isNext
                    isGreenLabel
                    text={
                      <S.ConfirmationContainer>
                        <S.ConfirmationWarning>
                          A report name is required, please check
                          <br /> your entries.
                        </S.ConfirmationWarning>
                      </S.ConfirmationContainer>
                    }
                    open={isErrorDialogOpened}
                    onClick={this.closeErrorDialog}
                    handleClose={this.closeErrorDialog}
                  />
                </form>
              )}
            />
          </S.FormContainer>
        </S.DialogContainer>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ newSites, people, queue }) => ({
  jobsList: newSites.jobsList,
  peopleList: people.list,
  isLoading: queue.loading,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...newSitesActions,
      peopleListRequest: peopleActions.peopleListRequest,
      createReportRequest: queueActions.createReportRequest,
      editReportRequest: queueActions.editReportRequest,
      deleteReportRequest: queueActions.deleteReportRequest,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportSetup);
