import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import theme from 'theme';
import { ActionButton, ActionsContainer, Dialog } from 'components/Dialogs/v1/Base';
import ConfirmationDialog from 'components/Dialogs/v1/Base/Confirmation';
import RadioGroup from 'components/RadioButtons';
import { UnitType } from 'configs/propTypes';
import { actions as unitsActions } from 'redux/units';
import { actions as unitActions } from 'redux/unit';
import { options } from '../../AddEditUnitDialog/constants';

import * as S from './styled';

const ListExchangeUploadDialog = ({ open, handleClose, unit }) => {
  const [isConfirmationDialogOpened, setIsConfirmationDialogOpened] = useState(false);
  const dispatch = useDispatch();

  const openConfirmationDialog = () => setIsConfirmationDialogOpened(true);

  const closeConfirmationDialog = () => setIsConfirmationDialogOpened(false);

  const onSubmit = values => {
    dispatch(unitActions.editUnitRequest({ params: { ...unit, UnitType: values.UnitType } }));

    openConfirmationDialog();
  };

  const importTenantToExchangeList = () => {
    const { SiteId, Id } = unit;
    dispatch(unitsActions.importTenantToListRequest({ siteId: SiteId, unitIds: Id }));
    closeConfirmationDialog();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onRequestClose={handleClose}
      title="Upload"
      titleColor={theme.mainRed}
      modalStyles={{ maxWidth: '45%' }}
    >
      <S.Container>
        <S.Text>{unit.Name}</S.Text>
        <S.Title>List Exchange Upload</S.Title>
        <S.Paragraph>
          The List Exchange allows Templates to be shared across all <br />
          your Sites and the Sites of other companies unless made <br /> &quot;private&quot;.
        </S.Paragraph>
        <Form
          onSubmit={onSubmit}
          initialValues={unit}
          render={({ handleSubmit }) => (
            <>
              <form onSubmit={handleSubmit}>
                <S.RadioButtonsWrapper>
                  <Field
                    component={RadioGroup}
                    items={options}
                    defaultValue="Regular"
                    name="UnitType"
                    labelStyle={{ width: '100%' }}
                    radioButtonStyle={{ borderTop: '2px solid #807F7F' }}
                  />
                </S.RadioButtonsWrapper>
                <ActionButton type="submit" label="Upload to List Exchange" isCancelNext />
              </form>
              <ConfirmationDialog
                title="Alert"
                maxWidth="80%"
                open={isConfirmationDialogOpened}
                handleClose={closeConfirmationDialog}
                text={
                  <div>
                    <S.Title>List Exchange Upload</S.Title>
                    <S.Paragraph>Are you sure?</S.Paragraph>
                    <S.Paragraph> Uploads cannot be reversed.</S.Paragraph>
                    <ActionsContainer gapValue={14}>
                      <ActionButton isNext label="Abort" onClick={closeConfirmationDialog} />
                      <ActionButton label="UPLOAD" onClick={importTenantToExchangeList} />
                    </ActionsContainer>
                  </div>
                }
              />
            </>
          )}
        />
      </S.Container>
    </Dialog>
  );
};

ListExchangeUploadDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  requestTenantToList: PropTypes.func.isRequired,
  editUnit: PropTypes.func.isRequired,
  unit: UnitType.isRequired,
};

export default ListExchangeUploadDialog;
