import http from '../index';

export const getUnitsList = (includeHidden, includeChecklistData, includeTaskData, filter) =>
  http.post(
    '/Unit/List',
    { filter },
    {
      params: {
        siteId: filter.SiteID,
        includeHidden: !!includeHidden,
        includeChecklistData,
        includeTaskData: filter.checklistType === 'quickTask' ? includeTaskData : undefined,
      },
    },
  );

export const postUnit = dto => http.post('/Unit/POST', { dto });

export const duplicateUnitRequest = (Id, encodedName) => http.post(`/Unit/Duplicate?unitId=${Id}&name=${encodedName}`);

export const getTenantToList = dto => http.post('/Unit/CopyUnitsToListExchange', dto);

export const getUnitsWithItems = dto => http.post('/Unit/GetUnitsWithItems', dto);

export const getListUnitsWithItems = siteId => http.post(`Unit/ListUnitsWithItems?siteId=${siteId}`);

export const getUpdateItems = dto => http.post('/Item/EditItemSequence', dto);
