import http from '../index';

export const fetchLimits = tenantId => http.post('TenantManagement/GetTenantLimitsInfo', { tenantId });

export const fetchItems = dto => http.post('Item/ListMasterItemsWithTasks', dto);

export const copyUnitsToTenantFromMaster = dto => http.post('Unit/CopyUnitsToTenantFromMaster', dto);

export const makeListTemplate = dto => http.post('Unit/CreateListTemplate', dto);

export const createItemListTemplate = dto => http.post('Item/CreateItemListTemplate', dto);

export const fetchAllUnits = () => http.post('Unit/ListMasterUnits');

export const getListMasterUnits = dto => http.post('Unit/ListMasterUnits', dto);

export const canImportUnit = dto => http.post('Unit/CanImportUnit', dto);

export const fetchUnitTaskStatus = unitId => http.post('Unit/GetTaskStatus', { unitId });

export const fetchUnitListTemplateTypes = () => http.post('Unit/ListTemplateTypes');
