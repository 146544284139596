import PropTypes from 'prop-types';

export const buildErrorMessage = ({ propName, props, componentName, typeNames }) =>
  Error(
    `Invalid prop ${propName}=${props[propName]}
     supplied to ${componentName}. Expected ${typeNames[0]} or ${typeNames[1]}.`,
  );

// eslint-disable-next-line consistent-return
export const NullOrObject = (props, propName, componentName) => {
  if ((typeof props[propName] !== 'object' && props[propName] !== null) || Array.isArray(props[propName])) {
    return new Error(
      buildErrorMessage({
        propName,
        props,
        componentName,
        typeNames: ['object', 'null'],
      }),
    );
  }
};

// eslint-disable-next-line consistent-return
export const NullOrString = (props, propName, componentName) => {
  if (typeof props[propName] !== 'string' && props[propName] !== null) {
    return new Error(
      buildErrorMessage({
        propName,
        props,
        componentName,
        typeNames: ['string', 'null'],
      }),
    );
  }
};

export const AssignableUsers = PropTypes.arrayOf(
  PropTypes.shape({
    Key: PropTypes.string,
    Value: PropTypes.string,
  }),
);

export const AssignedTaskType = PropTypes.shape({
  AssignedChecklistId: PropTypes.string,
  AssigneeId: PropTypes.string,
  AssigneeName: PropTypes.string,
  CompletedBy: PropTypes.string,
  CompletedOn: PropTypes.string,
  CreatedOn: PropTypes.string,
  Exceptions: PropTypes.string,
  ExecutionDate: PropTypes.string,
  HasBeenSignedOff: PropTypes.bool,
  Id: PropTypes.string,
  InstructionalNotes: PropTypes.string,
  IsActive: PropTypes.bool,
  ItemId: PropTypes.string,
  ItemInstructions: PropTypes.string,
  ItemName: PropTypes.string,
  ItemNotes: PropTypes.string,
  ItemSequence: PropTypes.number,
  OriginatingChecklistName: PropTypes.string,
  OriginatingTaskId: PropTypes.string,
  OriginatingTaskName: PropTypes.string,
  RuleData: PropTypes.shape({
    ActualFlow: PropTypes.number,
    ActualQty: PropTypes.number,
    ActualScannedCodeId: PropTypes.string,
    ActualScannedCodeValue: PropTypes.string,
    ActualTemperature: PropTypes.number,
    CurrentPhotos: PropTypes.number,
    ExpectedScanCode: PropTypes.string,
    FeedbackQuestion: PropTypes.string,
    FeedbackResponse: PropTypes.string,
    MaxFlow: PropTypes.number,
    MaxPhotos: PropTypes.number,
    MaxQty: PropTypes.number,
    MaxTemperature: PropTypes.number,
    MinFlow: PropTypes.number,
    MinPhotos: PropTypes.number,
    MinQty: PropTypes.number,
    MinTemperature: PropTypes.number,
    VerificationResponse: PropTypes.string,
    VerificationSpecification: PropTypes.string,
  }),
  RuleType: PropTypes.string,
  SignoffBy: PropTypes.string,
  SignoffComment: PropTypes.string,
  SignoffOn: PropTypes.string,
  SiteId: PropTypes.string,
  Status: PropTypes.string,
  UnitId: PropTypes.string,
  UnitName: PropTypes.string,
  UserComments: PropTypes.string,
});

export const BarcodeType = PropTypes.shape({
  CreatedOn: PropTypes.string,
  Id: PropTypes.string,
  MimeType: PropTypes.string,
  Name: PropTypes.string,
  SizeInBytes: PropTypes.number,
  Value: PropTypes.string,
});

export const DividedTasksType = PropTypes.shape({
  tasks: PropTypes.objectOf(PropTypes.arrayOf(AssignedTaskType)),
  amount: PropTypes.number,
});

export const CommonTasksType = PropTypes.shape({
  my: DividedTasksType,
  unassigned: DividedTasksType,
});

export const DocumentImageType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

export const DocumentItem = PropTypes.shape({
  BackgroundImageFileName: PropTypes.string,
  BackgroundImageId: PropTypes.string,
  BodyText: PropTypes.string,
  CreatedOn: PropTypes.string,
  FooterImageFileName: PropTypes.string,
  FooterImageId: PropTypes.string,
  HeaderImageFileName: PropTypes.string,
  HeaderImageId: PropTypes.string,
  HeaderText: PropTypes.string,
  Id: PropTypes.string,
  PageNumber: PropTypes.number,
});

export const GatewayType = PropTypes.shape({
  DeviceId: PropTypes.string,
  Id: PropTypes.string,
  IotHubName: PropTypes.string,
  Notes: PropTypes.string,
  Region: PropTypes.string,
  ResourceGroupNametes: PropTypes.string,
  SensorsCount: PropTypes.number,
  SerialNumber: PropTypes.string,
  SiteID: PropTypes.string,
  TenantID: PropTypes.string,
});

export const TeamType = PropTypes.shape({
  Name: PropTypes.string,
  AssistantId: PropTypes.string,
  ManagerId: PropTypes.string,
  Location: PropTypes.string,
  IsActive: PropTypes.bool,
  MembersCount: PropTypes.number,
  Id: PropTypes.string,
});

export const SensorType = PropTypes.shape({
  Id: PropTypes.string,
  ItemId: PropTypes.string,
  LevelsCount: PropTypes.number,
  Notes: PropTypes.string,
  SensorType: PropTypes.shape({}),
  SensorTypeID: PropTypes.string,
  SensorTypeVariable: PropTypes.string,
  SerialNumber: PropTypes.string,
  SiteId: PropTypes.string,
  Status: PropTypes.string,
  Type: PropTypes.string,
  UnitId: PropTypes.string,
});

export const SensorTypeType = PropTypes.shape({
  Id: PropTypes.string,
  LevelsCount: PropTypes.number,
  Notes: PropTypes.string,
  Type: PropTypes.string,
  AreLevelsDifferentFromTemplate: PropTypes.bool,
});

export const SensorLevelType = PropTypes.shape({
  Id: PropTypes.string,
  EndData: PropTypes.string,
  LevelName: PropTypes.string,
  StartData: PropTypes.string,
});

export const QueueItem = PropTypes.shape({
  AssigneeId: PropTypes.string,
  AssigneeName: PropTypes.string,
  TaskCompletePercent: PropTypes.number,
  TasksComplete: PropTypes.number,
  TasksRequireAttention: PropTypes.number,
  TasksTotal: PropTypes.number,
  UnitId: PropTypes.string,
  UnitName: PropTypes.string,
  UnitTasksCompletePercent: PropTypes.number,
});

export const PreparedForRenderTasksType = PropTypes.shape({
  day: CommonTasksType,
  week: CommonTasksType,
  past: CommonTasksType,
});

export const ScheduleData = PropTypes.shape({
  DailyRepeatInterval: PropTypes.string,
  DailyRepeatUntil: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  DailyStartDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  WeeklyRepeatInterval: PropTypes.string,
  WeeklyRepeatUntil: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  WeeklyStartDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  WeeklyRepeatDayName: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  MonthlyRepeatInterval: PropTypes.string,
  MonthlyRepeatUntil: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  MonthlyStartDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
});

export const ScheduleDto = PropTypes.shape({
  CreatedOn: PropTypes.string,
  Id: PropTypes.string,
  SheduleData: ScheduleData,
  ScheduleType: PropTypes.string,
});

export const ScheduleInfo = PropTypes.shape({
  ActiveStatus: PropTypes.string,
  CreatedOn: PropTypes.string,
  Id: PropTypes.string,
  Name: PropTypes.string,
  ScheduleDto,
  SupervisorId: PropTypes.string,
  SupervisorName: PropTypes.string,
  UnitId: PropTypes.string,
  UnitName: PropTypes.strin,
});

export const ShortcutSchedule = PropTypes.shape({
  SiteID: PropTypes.string,
  ShortcutID: PropTypes.string,
  RepeatCycle: PropTypes.number,
  RepeatInterval: PropTypes.number,
  StartDate: PropTypes.string,
  EndDate: PropTypes.string,
  AssignmentType: PropTypes.number,
  IsScheduled: PropTypes.bool,
  ManagementRules: PropTypes.oneOf([null, PropTypes.shape({})]), // need to add ManagementRules shape object
  PersonRules: PropTypes.oneOf([null, PropTypes.shape({})]), // need to add PersonRules shape object
  Name: PropTypes.oneOf([null, PropTypes.string]),
  Id: PropTypes.string,
});

export const ServerResourceType = PropTypes.shape({
  Name: PropTypes.string,
  Region: PropTypes.string,
});

export const HubType = PropTypes.shape({
  Name: PropTypes.string,
  Region: PropTypes.string,
  ResourceGroupName: PropTypes.string,
  SKU: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
});

export const SettingsListType = PropTypes.shape({
  BeaconDistance: PropTypes.number,
  BeaconDistanceItem: PropTypes.number,
  BeaconDistanceUnit: PropTypes.number,
  BeaconId: PropTypes.string,
  CreatedOn: PropTypes.string,
  DefaultWebAddress: NullOrString,
  ExistsLogoFromTenant: PropTypes.bool,
  ExistsLogoEmailFromTenant: PropTypes.bool,
  Id: PropTypes.string,
  Name: PropTypes.string,
  PhotoLimit: PropTypes.number,
  UniqueID: PropTypes.string,
  UseUniqueIDOnly: PropTypes.bool,
  SalutationTaskSpecialist: PropTypes.string,
  IntroTextTaskSpecialist: PropTypes.string,
  ClosingTextTaskSpecialist: PropTypes.string,
  SalutationManagerAdmin: PropTypes.string,
  IntroTextManagerAdmin: PropTypes.string,
  ClosingTextManagerAdmin: PropTypes.string,
  SalutationOEMManagerAdmin: PropTypes.string,
  IntroTextOEMManagerAdmin: PropTypes.string,
  ClosingTextOEMManagerAdmin: PropTypes.string,
});

export const SiteType = PropTypes.shape({
  Address: PropTypes.shape({
    City: PropTypes.string,
    Country: PropTypes.string,
    State: PropTypes.string,
    Street: PropTypes.string,
    Street2: PropTypes.string,
    ZipCode: PropTypes.string,
  }),
  AdministratorEmail: PropTypes.string,
  AdministratorName: PropTypes.string,
  AdministratorPhone: PropTypes.string,
  AdministratorTitle: PropTypes.string,
  BeaconDistance: PropTypes.number,
  BeaconDistanceItem: PropTypes.number,
  BeaconDistanceUnit: PropTypes.number,
  BeaconId: PropTypes.string,
  CreatedOn: PropTypes.string,
  Id: PropTypes.string,
  Name: PropTypes.string,
  Phone: PropTypes.string,
});

export const ShortcutSiteType = PropTypes.shape({
  AdditionalSiteId: PropTypes.string,
  IsScheduled: PropTypes.bool,
  ItemCount: PropTypes.number,
  SiteId: PropTypes.string,
  SiteName: PropTypes.string,
  UnitCount: PropTypes.number,
});

export const TaskDetailsType = PropTypes.shape({
  AssignedChecklistId: PropTypes.string,
  AssigneeId: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  AssigneeName: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  CompletedBy: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  CompletedOn: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  CreatedOn: PropTypes.string,
  Exceptions: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ExecutionDate: PropTypes.string,
  HasBeenSignedOff: PropTypes.bool,
  Id: PropTypes.string,
  InstructionalNotes: PropTypes.string,
  IsActive: PropTypes.bool,
  ItemId: PropTypes.string,
  ItemInstructions: PropTypes.string,
  ItemName: PropTypes.string,
  ItemNotes: PropTypes.string,
  ItemSequence: PropTypes.number,
  OriginatingChecklistName: PropTypes.string,
  OriginatingTaskId: PropTypes.string,
  OriginatingTaskName: PropTypes.string,
  RuleData: PropTypes.object,
  RuleType: PropTypes.string,
  SignoffBy: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  SignoffComment: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  SignoffOn: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  SiteId: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  Status: PropTypes.string,
  UnitId: PropTypes.string,
  UnitName: PropTypes.string,
  UserComments: PropTypes.string,
});

export const TaskNotesPhotos = PropTypes.arrayOf(
  PropTypes.shape({
    AssignedTaskId: PropTypes.string,
    AssignedTaskName: PropTypes.string,
    CreatedOn: PropTypes.string,
    Id: PropTypes.string,
    MimeType: PropTypes.string,
    Name: PropTypes.string,
    SiteInBytes: PropTypes.number,
    src: PropTypes.string,
  }),
);

export const TaskPhoto = PropTypes.arrayOf(
  PropTypes.shape({
    CreatedOn: PropTypes.string,
    Id: PropTypes.string,
    ItemId: PropTypes.string,
    ItemName: PropTypes.string,
    MineType: PropTypes.string,
    Name: PropTypes.string,
    SizeInBytes: PropTypes.number,
    src: PropTypes.string,
  }),
);

export const TaskTemplates = PropTypes.arrayOf(
  PropTypes.shape({
    AssigneeId: PropTypes.string,
    AssigneeName: PropTypes.string,
    ChecklistId: PropTypes.string,
    ChecklistName: PropTypes.string,
    CreatedOn: PropTypes.string,
    GroupSortOrder: PropTypes.number,
    Id: PropTypes.string,
    ItemId: PropTypes.string,
    ItemName: PropTypes.string,
    SubSortOrder: PropTypes.number,
    TaskTemplateId: PropTypes.string,
    TaskTemplateName: PropTypes.string,
  }),
);

export const TierType = {
  Id: PropTypes.string,
  Name: PropTypes.string,
  IotCharge: PropTypes.number,
  MeeteringGroupID: PropTypes.string,
  TasksCharge: PropTypes.number,
  TenantMaxItemsEach: PropTypes.number,
  TenantMaxSensorsEach: PropTypes.number,
  TenantMaxSitesEach: PropTypes.number,
  TotalCharge: PropTypes.number,
};

export const TenantType = PropTypes.shape({
  AllowSubmitToListExchange: PropTypes.bool,
  ConnectionSqlServer: PropTypes.string,
  ConnectionDbName: PropTypes.string,
  ConnectionUserName: PropTypes.string,
  ConnectionPassword: PropTypes.string,
  CreatedOn: PropTypes.string,
  DefaultAdminExternalId: PropTypes.string,
  DefaultAdminId: PropTypes.string,
  DefaultAdminName: PropTypes.string,
  DefaultAdminPassword: PropTypes.string,
  Id: PropTypes.string,
  IndustryId: PropTypes.string,
  IndustryName: PropTypes.string,
  IsOEM: PropTypes.bool,
  Name: PropTypes.string,
  Notes: PropTypes.string,
  Status: PropTypes.string,
  TierId: PropTypes.string,
  TierInfo: PropTypes.shape(TierType),
});

export const VersionLegalType = PropTypes.shape({
  VersionNumber: PropTypes.number,
  VersionNotes: PropTypes.string,
  CopyrightText: PropTypes.string,
  PrivacyUrl: PropTypes.string,
  Id: PropTypes.string,
});

export const TierFullType = PropTypes.shape({
  Id: PropTypes.string,
  Name: PropTypes.string,
  TenantMaxSitesEach: PropTypes.number,
  TenantMaxItemsEach: PropTypes.number,
  TenantMaxSensorsEach: PropTypes.number,
  TasksCharge: PropTypes.number,
  IotCharge: PropTypes.number,
  MeeteringGroupId: PropTypes.string,
});

export const ToggleItem = PropTypes.shape({
  key: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.number,
});

export const UnitItemPdfType = PropTypes.shape({
  CreatedOn: PropTypes.string,
  Id: PropTypes.string,
  ItemId: PropTypes.string,
  ItemName: PropTypes.string,
  MimeType: PropTypes.string,
  Name: PropTypes.string,
  SizeInBytes: PropTypes.number,
  UnitId: PropTypes.string,
  UnitName: PropTypes.string,
});

export const UnitItemPictureType = PropTypes.shape({
  CreatedOn: PropTypes.string,
  Id: PropTypes.string,
  ItemId: PropTypes.string,
  ItemName: PropTypes.string,
  MimeType: PropTypes.string,
  Name: PropTypes.string,
  SizeInBytes: PropTypes.number,
});

export const UnitItemTaskTemplateType = PropTypes.shape({
  CreatedOn: PropTypes.string,
  Id: PropTypes.string,
  InstructionalNotes: PropTypes.string,
  ItemId: PropTypes.string,
  Name: PropTypes.string,
  RuleData: PropTypes.shape({
    ExpectedLowerBound: PropTypes.number,
    ExpectedScanCode: PropTypes.string,
    ExpectedUpperBound: PropTypes.number,
    MaxAttachments: PropTypes.number,
    MinAttachments: PropTypes.number,
    Specification: PropTypes.string,
  }),
  RuleType: PropTypes.string,
});

export const UnitItemType = PropTypes.shape({
  Barcode: PropTypes.oneOfType([PropTypes.string, BarcodeType]),
  BeaconDistance: PropTypes.number,
  BeaconMinor: PropTypes.number,
  CreatedOn: PropTypes.string,
  Description: PropTypes.string,
  Id: PropTypes.string,
  Instructions: PropTypes.string,
  Name: PropTypes.string,
  Notes: PropTypes.string,
  Sequence: PropTypes.number,
  UnitId: PropTypes.string,
  UnitName: PropTypes.string,
});

export const UnitPdfType = PropTypes.shape({
  CreatedOn: PropTypes.string,
  Id: PropTypes.string,
  ItemId: PropTypes.string,
  ItemName: PropTypes.string,
  MimeType: PropTypes.string,
  Name: PropTypes.string,
  SizeInBytes: PropTypes.number,
  UnitId: PropTypes.string,
  UnitName: PropTypes.string,
});

export const UnitPictureType = PropTypes.shape({
  CreatedOn: PropTypes.string,
  Id: PropTypes.string,
  MimeType: PropTypes.string,
  Name: PropTypes.string,
  SizeInBytes: PropTypes.number,
  UnitId: PropTypes.string,
  UnitName: PropTypes.string,
});

export const UnitType = PropTypes.shape({
  Barcode: PropTypes.oneOfType([PropTypes.string, BarcodeType]),
  BeaconDistance: PropTypes.number,
  BeaconMajor: PropTypes.number,
  CreatedOn: PropTypes.string,
  Description: PropTypes.string,
  Id: PropTypes.string,
  LocationDescription: PropTypes.string,
  ModelNumber: PropTypes.string,
  Name: PropTypes.string,
  SiteId: PropTypes.string,
  SiteName: PropTypes.string,
  ItemCount: PropTypes.number,
  TotalCountChecklist: PropTypes.number,
  VendorEmail: PropTypes.string,
  VendorName: PropTypes.string,
  VendorNotes: PropTypes.string,
  VendorPhone: PropTypes.string,
  YearBuilt: PropTypes.string,
  TemplateTypeId: PropTypes.string,
  TemplateTypeName: PropTypes.string,
  UnitType: PropTypes.oneOf(['Regular', 'Demo', 'Private']),
});

export const UserType = PropTypes.shape({
  checked: PropTypes.bool,
  CreatedOn: PropTypes.string,
  Email: PropTypes.string,
  Id: PropTypes.string,
  JobFunction: PropTypes.string,
  Name: PropTypes.string,
  Phone: PropTypes.string,
  Status: PropTypes.string,
  SubmittedPassword: PropTypes.string,
  UserType: PropTypes.string,
});

export const NotificationTime = PropTypes.shape({
  Hour: PropTypes.string,
  Minute: PropTypes.string,
  Period: PropTypes.oneOf(['am', 'pm']),
}).isRequired;

export const ResourceGroupType = {
  tenant: PropTypes.shape({
    name: PropTypes.string,
    industry: PropTypes.string,
    adminName: PropTypes.string,
    id: PropTypes.string,
  }),
  hubs: PropTypes.oneOf([
    PropTypes.arrayOf(
      PropTypes.shape({
        HubName: PropTypes.string,
        GatewaysInHubNumber: PropTypes.number,
      }),
    ),
    PropTypes.shape({
      name: PropTypes.string,
      gatewaysNumber: PropTypes.number,
    }),
  ]),
  resourceGroup: PropTypes.shape({
    name: PropTypes.string,
    location: PropTypes.string,
  }),
  site: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    city: PropTypes.string,
  }),
  hubsNumber: PropTypes.number,
  gatewaysNumber: PropTypes.number,
};

export const DeleteResourceGroupType = {
  type: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.oneOf([
      PropTypes.shape({
        fieldName: PropTypes.string,
        fieldData: PropTypes.string,
      }),
      ResourceGroupType.hubs,
    ]),
  ),
};

export const ValueLabelType = {
  value: PropTypes.string,
  label: PropTypes.string,
};

export const MeteringGroupType = PropTypes.shape({
  Id: PropTypes.string,
  IndustryID: PropTypes.string,
  IndustryName: PropTypes.string,
  Name: PropTypes.string,
  Notes: PropTypes.string,
  TiersCount: PropTypes.number,
});

export const IndustryType = PropTypes.shape({
  Id: PropTypes.string,
  Name: PropTypes.string,
  MeeteringGroupID: PropTypes.string,
  MeeteringGroupName: PropTypes.string,
});

export const TiersListType = PropTypes.arrayOf(PropTypes.shape(TierType));

export const VersionType = PropTypes.shape({
  CopyrightText: PropTypes.string.isRequired,
  VersionNotes: PropTypes.string.isRequired,
  VersionNumber: PropTypes.string.isRequired,
  PrivacyUrl: PropTypes.string.isRequired,
});

export const OEMType = PropTypes.shape({
  Id: PropTypes.string,
  Name: PropTypes.string,
  Version: PropTypes.number,
  CreatedOn: PropTypes.string,
});

export const OptionType = PropTypes.shape({
  Id: PropTypes.string,
  Name: PropTypes.string,
});

export const OptionsType = PropTypes.shape({
  category: PropTypes.arrayOf(OptionType),
  unitOfMeasure: PropTypes.arrayOf(OptionType),
  weightStandard: PropTypes.arrayOf(OptionType),
  type: PropTypes.arrayOf(OptionType),
  sizingStandard: PropTypes.arrayOf(OptionType),
  materialType: PropTypes.arrayOf(OptionType),
});

export const PartType = PropTypes.shape({
  Id: PropTypes.string,
  LinksCount: PropTypes.number,
  MyPartCategory: PropTypes.string,
  MyPartCategoryID: PropTypes.string,
  MyPartID: PropTypes.string,
  MyPartName: PropTypes.string,
  MyPartType: PropTypes.string,
  MyPartTypeID: PropTypes.string,
  MyPartUnitOfMeasure: PropTypes.string,
  MyPartUnitOfMeasureID: PropTypes.string,
});

export const PartsListType = PropTypes.arrayOf(PartType);

export const PartGroupType = PropTypes.shape({
  Id: PropTypes.string,
  LinksCount: PropTypes.number,
  Name: PropTypes.string,
  Description: PropTypes.string,
  Parts: PartsListType,
});

export const PartGroupsListType = PropTypes.arrayOf(PartGroupType);

const LocationOptionNamesType = PropTypes.oneOf([
  'SiteID',
  'SiteName',
  'SiteCountry',
  'SiteZIPCode',
  'SiteCity',
  'SiteIgnore',
]);

const UnitOptionNamesType = PropTypes.oneOf([
  'UnitName',
  'UnitDescription',
  'UnitModelNumber',
  'UnitLocationDescription',
  'UnitVendorName',
  'UnitYearBuilt',
  'UnitIgnore',
]);

const ItemOptionNamesType = PropTypes.oneOf(['ItemName', 'ItemDescription', 'ItemInstructions', 'ItemIgnore']);

export const ShortCutRuleType = PropTypes.arrayOf(
  PropTypes.shape({
    OptionName: PropTypes.oneOfType([LocationOptionNamesType, ItemOptionNamesType, UnitOptionNamesType]),
    OptionConstraint: PropTypes.oneOf([0, 1, 2, 4, 8, 16, 32, 64, -1]),
    OptionValue: PropTypes.string,
    MatchOperator: PropTypes.oneOf([0, 1, -1]),
  }),
);

export const ShortCutType = PropTypes.shape({
  LastExecutionDate: PropTypes.string,
  Id: PropTypes.string,
  Name: PropTypes.string,
  OwnerID: PropTypes.string,
  OwnerName: PropTypes.string,
  Type: PropTypes.oneOf([0, 1]),
  LocationRules: ShortCutRuleType,
  UnitRules: ShortCutRuleType,
  ItemRules: ShortCutRuleType,
});

export const ChildrenType = PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]);

export const CharacteristicListType = PropTypes.arrayOf(
  PropTypes.shape({
    CreatedOn: PropTypes.string.isRequired,
    DataColumns: PropTypes.objectOf(PropTypes.string).isRequired,
    Id: PropTypes.string.isRequired,
    IndexItemId: PropTypes.string.isRequired,
    IndexItemName: PropTypes.string.isRequired,
    IsConfig: PropTypes.bool.isRequired,
  }),
);

export const IndexItemType = PropTypes.shape({
  AdditionalIndexID: PropTypes.string,
  CreatedOn: PropTypes.string,
  EndDate: PropTypes.string,
  Id: PropTypes.string,
  LiveDate: PropTypes.string,
  Name: PropTypes.string,
  State: PropTypes.string,
  Type: PropTypes.string,
});

export const PastDueLimitSiteType = PropTypes.shape({
  CreatedOn: PropTypes.string,
  PastDueLimit: PropTypes.number,
  PastDueLimitPeriodType: PropTypes.number,
  Id: PropTypes.string,
  Site: PropTypes.shape({ SiteType }),
});

export const LandingPageSectionType = PropTypes.shape({
  CreatedOn: PropTypes.string,
  FeedID: PropTypes.string,
  FeedType: PropTypes.oneOf([0, 1, 2, 4, -1]),
  Header: PropTypes.string,
  Id: PropTypes.string,
  IsVisible: PropTypes.bool,
  LandingPageID: PropTypes.string,
  Sequence: PropTypes.number,
  Subtitle: PropTypes.string,
});

export const TutorialVideosType = PropTypes.arrayOf(
  PropTypes.shape({
    Header: PropTypes.string,
    Subtitle: PropTypes.string,
    Id: PropTypes.string,
    LandingPageSectionID: PropTypes.string,
    Name: PropTypes.string,
    PopupPicture: PropTypes.string,
    PreviewPicture: PropTypes.string,
    Video: PropTypes.string,
  }),
);

export const AssetCategoryType = PropTypes.shape({
  CreatedOn: PropTypes.string,
  Id: PropTypes.string,
  Name: PropTypes.string,
  Description: PropTypes.string,
  TypeCount: PropTypes.number,
});

export const AssetType = PropTypes.shape({
  CreatedOn: PropTypes.string,
  Id: PropTypes.string,
  Name: PropTypes.string,
  masterAssetCategoryID: PropTypes.string,
  masterAssetCategoryName: PropTypes.string,
});

export const SiteUserManagerType = PropTypes.shape({
  CreatedOn: PropTypes.string,
  Id: PropTypes.string,
  IsHidden: PropTypes.bool,
  SiteId: PropTypes.string,
  SiteName: PropTypes.string,
  Status: PropTypes.string,
  UserId: PropTypes.string,
  UserName: PropTypes.string,
  UserType: PropTypes.string,
});

export const FilterTeamMemberType = PropTypes.shape({
  CreatedOn: PropTypes.string,
  Id: PropTypes.string,
  IsMultiGroupUser: PropTypes.bool,
  Name: PropTypes.string,
  TeamID: PropTypes.string,
  User: PropTypes.string,
  UserID: PropTypes.string,
});

export const TeamUserType = PropTypes.shape({
  CreateOn: PropTypes.string,
  Email: PropTypes.string,
  FirstName: PropTypes.string,
  Id: PropTypes.string,
  IsHidden: PropTypes.bool,
  JobFunctionID: PropTypes.string,
  JobFunctionName: PropTypes.string,
  LastName: PropTypes.string,
  LocationsCount: PropTypes.number,
  Name: PropTypes.string,
  OverdueTasksCount: PropTypes.number,
  Phone: PropTypes.string,
  SendDailyMail: PropTypes.bool,
  SendIotMail: PropTypes.bool,
  SendIotPush: PropTypes.bool,
  SendIotTxt: PropTypes.bool,
  Status: PropTypes.string,
  SubmittedPassword: PropTypes.string,
  TasksCount: PropTypes.number,
  UserAvailabilityOptionID: PropTypes.string,
  UserAvailabilityOptionName: PropTypes.string,
  UserType: PropTypes.string,
});

export const MembersForAssigmentType = PropTypes.shape({
  IsMultiGroupUser: PropTypes.bool,
  ...TeamUserType,
});

export const TeamMemberType = PropTypes.shape({
  CreatedOn: PropTypes.string,
  Id: PropTypes.string,
  Name: PropTypes.string,
  TeeamID: PropTypes.string,
  User: TeamUserType,
  UserID: PropTypes.string,
});

export const EditTeamType = PropTypes.shape({
  Assistant: PropTypes.string,
  AssistantID: TeamUserType,
  CreateOn: PropTypes.string,
  Id: PropTypes.string,
  IsActive: PropTypes.bool,
  Location: PropTypes.string,
  Manager: TeamUserType,
  ManagerID: PropTypes.string,
  MembersCount: PropTypes.number,
  Name: PropTypes.string,
  Notes: PropTypes.string,
  TeamStatus: PropTypes.shape({
    CreateOn: PropTypes.string,
    Id: PropTypes.string,
    Name: PropTypes.string,
  }),
});
