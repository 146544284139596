import moment from 'moment';

export const validator = values => {
  const errors = {};

  if (!values['datetime-Archive']) {
    if (!values.StartDate) {
      errors.StartDate = 'Required';
    }

    if (!values.EndDate) {
      errors.EndDate = 'Required';
    }

    if (moment(values.EndDate).isSameOrBefore(moment(values.StartDate))) {
      errors.EndDate = 'New End date can not be earlier than Start date';
    }
  }

  return errors;
};

export default validator;
