import React from 'react';
import PropTypes from 'prop-types';
import { Field, FormSpy } from 'react-final-form';
import { connect } from 'react-redux';
import MenuItem from 'material-ui/MenuItem';
import http from '../../../../http';
import { UnitType } from 'configs/propTypes';
import RadioButtons from 'components/RadioButtons';
import withInputModal from 'components/Dialogs/withInputModal';
import TextTooltipFieldAdapter from 'components/Forms/TextTooltipFieldAdapter';
import SelectFieldAdapter from 'components/Forms/SelectFieldAdapter';
import { BorderRadiusContainer } from 'components/Layout/Containers';

import DeleteConfirmationDialog from './DeleteConfirmationDialog';
import { optionalVendorInfo, options } from './constants';
import { mapDispatchToProps, mapStateToProps } from './index.props';
import validate from './validator';

import * as S from './styled';

let barcode = '';
let timestamp = '';

const getImageSource = (unitId, barcodeValue, initialBarcode) => {
  if (barcodeValue === initialBarcode && barcodeValue !== barcode) {
    timestamp = Date.now();
    barcode = barcodeValue;
  }

  return (
    `${http.defaults.baseURL}/UnitBarcode/Get` +
    `?unitId=${unitId}&siteId=${http.defaults.siteId}&width=200&height=200&inline=true&_dc=${timestamp}`
  );
};

const tooltipText = {
  scanCode: <p>If none available enter unique unit/part name or other identifier (numbers or text)</p>,
};

const AddEditUnitForm = ({
  form,
  values,
  initialValues,
  isDefaultBarcode,
  setDefaultBarcode,
  title,
  unitID,
  requestUnitDefaultBarcode,
  templateTypesList,
  ...restProps
}) => {
  const isEditModal = title === 'Edit Template Info';

  const resetForm = defaultBarcode => {
    form.reset({
      ...values,
      Barcode: {
        ...values.Barcode,
        Value: defaultBarcode,
      },
    });

    setDefaultBarcode(true);
  };

  const resetBarcode = () => {
    const params = { id: unitID };

    requestUnitDefaultBarcode({ params, reset: resetForm });
  };

  return (
    <>
      <S.FormContainer>
        <BorderRadiusContainer>
          <S.Label isListTemplateInfo>List Template Info</S.Label>
          <S.InputWrapper>
            <Field component={S.TextField} hintText="Unit/Object Name" name="Name" maxLength="255" />
            <Field component={S.TextField} hintText="Template Description" name="Description" maxLength="255" />
            <Field component={SelectFieldAdapter} floatingLabelText="Template Type" name="TemplateTypeName">
              {templateTypesList.map((item, i) => (
                <MenuItem key={i} primaryText={item.Name} value={item.Name} />
              ))}
            </Field>
          </S.InputWrapper>
          <S.SecondaryBlock>
            <S.Block>
              <S.Content>
                <FormSpy
                  subscription={{ values: true }}
                  initialBarcode={initialValues.Barcode && initialValues.Barcode.Value}
                >
                  {/* eslint-disable-next-line no-shadow */}
                  {({ initialBarcode, values }) =>
                    values.Barcode && values.Id && initialValues.Barcode ? (
                      <S.Image
                        src={getImageSource(values.Id, values.Barcode.Value, initialBarcode)}
                        alt={values.Barcode.Value}
                      />
                    ) : (
                      <span>QR</span>
                    )
                  }
                </FormSpy>
              </S.Content>
            </S.Block>
            <S.RadioButtonsWrapper>
              <Field component={RadioButtons} items={options} defaultValue="Regular" name="UnitType" />
            </S.RadioButtonsWrapper>
          </S.SecondaryBlock>
        </BorderRadiusContainer>

        <BorderRadiusContainer>
          <S.Label isUnitInfo>Unit Info</S.Label>

          <S.InputWrapper>
            <Field component={S.TextField} hintText="Model" name="ModelNumber" maxLength="255" />
            <Field component={S.TextField} hintText="Built/Year" name="YearBuilt" maxLength="255" />
            <TextTooltipFieldAdapter
              name="Barcode.Value"
              hintText="Scan/QR code"
              tooltip={tooltipText.scanCode}
              tooltipWidth="160px"
              handleReset={resetBarcode}
              setDefaultBarcode={setDefaultBarcode}
              isDefaultBarcode={isDefaultBarcode}
              isHelp
              isReset={isEditModal}
            />
            <Field component={S.TextField} hintText="Location" name="LocationDescription" maxLength="255" />
            <Field
              disabled
              component={S.TextField}
              hintText="Beacon Major"
              name="BeaconMajor"
              type="number"
              maxLength="255"
            />
          </S.InputWrapper>
        </BorderRadiusContainer>

        <BorderRadiusContainer>
          <S.Label>Optional Vendor Info</S.Label>

          <S.InputWrapper>
            {optionalVendorInfo.map(vendor => (
              <Field component={S.TextField} {...vendor} />
            ))}
          </S.InputWrapper>
        </BorderRadiusContainer>
      </S.FormContainer>
      {isEditModal && <DeleteConfirmationDialog unit={initialValues} {...restProps} />}
    </>
  );
};

AddEditUnitForm.propTypes = {
  isDefaultBarcode: PropTypes.bool,
  form: PropTypes.object.isRequired,
  initialValues: UnitType.isRequired,
  requestUnitDefaultBarcode: PropTypes.func.isRequired,
  setDefaultBarcode: PropTypes.func,
  title: PropTypes.string.isRequired,
  unitID: PropTypes.string,
  values: PropTypes.object,
  isListDeleteModalOpened: PropTypes.bool,
  isChecklistsDeletedInfoModalOpened: PropTypes.bool,
  isTasksDeletedInfoModalOpened: PropTypes.bool,
  history: PropTypes.object,
  handleClickChecklistDeletedInfoModal: PropTypes.func,
  handleCloseTasksDeletedInfoModal: PropTypes.func,
  handleCloseChecklistsDeletedInfoModal: PropTypes.func,
  handleClickListDeleteModal: PropTypes.func,
  handleCloseListDeleteModal: PropTypes.func,
  onDeleteUnitApprove: PropTypes.func,
  sitesAvailableToUserFullInfo: PropTypes.array.isRequired,
  listTaskStatus: PropTypes.object.isRequired,
  selectedSite: PropTypes.string.isRequired,
  templateTypesList: PropTypes.string.isRequired,
};

AddEditUnitForm.defaultProps = {
  isDefaultBarcode: false,
  setDefaultBarcode: () => {},
  unitID: null,
  values: {},
  history: {},
  isListDeleteModalOpened: false,
  isChecklistsDeletedInfoModalOpened: false,
  isTasksDeletedInfoModalOpened: false,
  handleCloseChecklistsDeletedInfoModal: () => {},
  handleClickChecklistDeletedInfoModal: () => {},
  handleCloseTasksDeletedInfoModal: () => {},
  handleClickListDeleteModal: () => {},
  handleCloseListDeleteModal: () => {},
  onDeleteUnitApprove: () => {},
};

const AddEditUnitWithInput = withInputModal(AddEditUnitForm);
const AddEditUnitDialog = ({ unit, ...rest }) => (
  <AddEditUnitWithInput {...rest} initialValues={{ ...unit }} validate={validate} />
);

AddEditUnitDialog.defaultProps = {
  unit: {
    Barcode: { Value: '' },
  },
  withDelete: false,
};

AddEditUnitDialog.propTypes = {
  unit: UnitType,
  withDelete: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditUnitDialog);
